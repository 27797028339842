import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Profile from "./images/sajith.png";
import PeerlistLogo from "./images/peerlist.png";
import { ReactComponent as ReactSVG } from "./images/React.svg";
import { ReactComponent as TypescriptSVG } from "./images/TypeScript.svg";
import { ReactComponent as JavascriptSVG } from "./images/Javascript.svg";
import { ReactComponent as NextjsSVG } from "./images/Nextjs.svg";
import { ReactComponent as NodeJsSVG } from "./images/NodeJs.svg";
import { ReactComponent as MongodbSVG } from "./images/Mongodb.svg";
import { ReactComponent as WorkSVG } from "./images/work.svg";
import { ReactComponent as GithubSVG } from "./images/github.svg";
import { ReactComponent as LinkedInSVG } from "./images/linkedin.svg";
import { ReactComponent as AngularSVG } from "./images/angular.svg";
import { ReactComponent as VueJSSVG } from "./images/vuejs.svg";
import { ReactComponent as HTMLSVG } from "./images/html.svg";
import { ReactComponent as CSSSVG } from "./images/css.svg";
import { ReactComponent as PHPSVG } from "./images/php.svg";
import { ReactComponent as LaravelSVG } from "./images/laravel.svg";
import { ReactComponent as NestJSSVG } from "./images/nestjs.svg";
import { ReactComponent as MySQLSVG } from "./images/mysql.svg";
import { ReactComponent as DockerSVG } from "./images/docker.svg";
import { ReactComponent as AWSSVG } from "./images/aws.svg";
import { ReactComponent as PythonSVG } from "./images/python.svg";
import { ReactComponent as FirebaseSVG } from "./images/firebase.svg";
import { ReactComponent as FlutterSVG } from "./images/flutter.svg";
import { ReactComponent as AndroidSVG } from "./images/android.svg";
import { ReactComponent as IOSSVG } from "./images/ios.svg";
import { ReactComponent as PlaystoreSVG } from "./images/playstore.svg";
import { ReactComponent as AppstoreSVG } from "./images/appstore.svg";

import Resume from "./Sajith-Resume-2022-06-27.pdf";

import {
  PhoneIcon,
  MailIcon,
  DocumentDownloadIcon,
} from "@heroicons/react/outline";

function App() {
  useEffect(() => {
    document.title = "Sajith K";
  }, []);

  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0,
        staggerChildren: 0.04,
      },
    },
  };

  const line1 = "👋 Hi I am Sajith K";
  const line2 = "Full stack Developer";
  const line3 = "based in Kerala, India 🇮🇳";

  return (
    <div className="w-full h-full pt-12 sm:pt-20 px-6 container mx-auto sm:px-6 lg:px-8">
      {/* Phone and Email */}
      <div className="w-full font-medium text-lg text-brand">
        <a href="tel:+917403103147" target="_blank" rel="noreferrer">
          <div className="flex w-fit items-center">
            <PhoneIcon className="h-5 w-5 mr-2" /> 7403103147
          </div>
        </a>
        <a
          href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=sajithmenon93@gmail.com&tf=1"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex w-fit items-center my-3">
            <MailIcon className="h-5 w-5 mr-2" /> sajithmenon93@gmail.com
          </div>
        </a>
        <a
          href="https://www.linkedin.com/in/iamsajithk/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex w-fit items-center my-3">
            <LinkedInSVG className="h-5 w-5 mr-2" /> Checkout my LinkedIn
          </div>
        </a>
        <a
          href="https://peerlist.io/iamsajithk"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex w-fit items-center my-3">
          <img
            src={PeerlistLogo}
            alt="profile"
            className="h-80 rounded-xl  content-center peerlist-logo"
          /> Checkout my Peerlist
          </div>
        </a>
      </div>
      {/* Welcome Heading and Profile */}
      <div className="my-16 sm:my-28 flex-col sm:flex-row flex sm:justify-between sm:items-center">
        <div className="flex w-fit flex-col items-start text-brand">
          <motion.h3
            className="load-screen--message"
            variants={sentence}
            initial="hidden"
            animate="visible"
          >
            {/* <p className="text-2xl font-medium my-1">
                👋 Hi I am Sajith K
              </p> */}
            <div className="text-2xl font-medium my-1">
              {line1.split("").map((char, index) => {
                return (
                  <motion.span key={char + "-" + index} variants={letter}>
                    {char}
                  </motion.span>
                );
              })}
            </div>
            <div className="font-serif font-bold text-3xl sm:text-4xl md:text-6xl xl:text-7xl my-1">
              {line2.split("").map((char, index) => {
                return (
                  <motion.span key={char + "-" + index} variants={letter}>
                    {char}
                  </motion.span>
                );
              })}
            </div>
            <div className="font-serif text-3xl my-1">
              {line3.split("").map((char, index) => {
                return (
                  <motion.span key={char + "-" + index} variants={letter}>
                    {char}
                  </motion.span>
                );
              })}
            </div>
          </motion.h3>
          <a href={Resume} target="_blank" rel="noreferrer">
            <button className="flex items-center my-10 bg-indigo-600 text-white font-semibold px-8 py-3 rounded hover:bg-indigo-800  active:ring active:ring-indigo-300">
              <DocumentDownloadIcon className="w-5 h-5 mr-2" />
              Download my Resume
            </button>
          </a>
        </div>
        <div>
          <img
            src={Profile}
            alt="profile"
            className="h-80 rounded-xl  content-center"
          />
        </div>
      </div>
      {/* About my work */}
      <div className="sm:mt-44 grid grid-cols-4 gap-x-5 items-center ">
        <div className="col-span-3">
          <div>
            <p className="font-medium text-indigo-600">ABOUT MY WORK</p>
          </div>
          <div className="text-brand mt-8 col-span-4">
            <p className="font-serif font-bold text-3xl sm:text-4xl md:text-5xl xl:text-6xl my-1 ">
              11+ years of experience
            </p>
            <p className="font-serif text-3xl sm:text-4xl md:text-5xl xl:text-6xl mt-2">
              as a developer
            </p>
            <p className="mt-5 text-lg sm:text-xl text-zinc-700">
              I am currently working at{" "}
              <span className="text-2xl text-brand font-semibold underline cursor-pointer">
                <a
                  href="https://www.kudoswall.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  KudosWall
                </a>
              </span>{" "}
              as a Solutions Architect.
            </p>
            <p className="my-1 text-lg sm:text-xl text-zinc-700">
              I focus on front-end, back-end and mobile app development.
            </p>
            <div className="flex flex-col sm:flex-row">
              <div className="sm:my-14">
                <p className="text-brand text-2xl font-semibold">
                  BACK-END DEVELOPMENT
                </p>
                <p className="text-md sm:text-xl text-zinc-700 w-fit">
                  I have working knowledge of PHP and NodeJS to build RESTful APIs using the frameworks Laravel and NestJS. As databases I
                  have worked with MySQL and MongoDB. And have experience in dockerising applications and deploying to AWS. I use Python for all general purpose scripting tasks like DB backups.
                </p>
                <div className="grid grid-cols-10 my-6 gap-y-4">
                  <PHPSVG className="h-12 w-12" />
                  <NodeJsSVG className="h-12 w-12" />
                  <LaravelSVG className="h-12 w-12" />
                  <NestJSSVG className="h-12 w-12" />
                  <MySQLSVG className="h-12 w-12" />
                  <MongodbSVG className="h-12 w-12" />
                  <DockerSVG className="h-12 w-12" />
                  <AWSSVG className="h-12 w-12" />
                  <PythonSVG className="h-12 w-12" />
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row">
              <div className="my-14">
                <p className="text-brand text-2xl font-semibold">
                  FRONT-END DEVELOPMENT
                </p>
                <p className="text-md sm:text-xl text-zinc-700 w-fit">
                  I currently work in Angular. I have knowledge of ReactJS, VueJS, NextJS, Typescript, Javascript, HTML and CSS.
                </p>
                <div className="grid grid-cols-10 my-6 gap-y-4">
                  <AngularSVG className="h-12 w-12" />
                  <ReactSVG className="h-12 w-12" />
                  <VueJSSVG className="h-12 w-12" />
                  <NextjsSVG className="h-12 w-12" />
                  <TypescriptSVG className="h-12 w-12" />
                  <JavascriptSVG className="h-12 w-12" />
                  <HTMLSVG className="h-12 w-12" />
                  <CSSSVG className="h-12 w-12" />
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row">
              <div className="my-14">
                <p className="text-brand text-2xl font-semibold">
                  MOBILE APP DEVELOPMENT
                </p>
                <p className="text-md sm:text-xl text-zinc-700 w-fit">
                  I have knowledge of creating mobile applications for both Android and iOS using cross platform framework Flutter. And also have experience in publishing apps to both iOS App Store and Google Play Store.
                </p>
                <div className="grid grid-cols-10 my-6 gap-y-4">
                  <FlutterSVG className="h-12 w-12" />
                  <FirebaseSVG className="h-12 w-12" />
                  <AndroidSVG className="h-12 w-12" />
                  <PlaystoreSVG className="h-12 w-12" />
                  <IOSSVG className="h-12 w-12" />
                  <AppstoreSVG className="h-12 w-12" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 items-center justify-items-center md:block hidden">
          <WorkSVG className="w-96" />
        </div>
      </div>

      {/* Projects */}

      <div className="w-full sm:my-38 my-24">
        <p className="w-full text-center font-serif font-medium text-brand text-2xl sm:text-3xl md:text-4xl xl:text-5xl my-1 hover:underline hover:cursor-pointer">
          <a
            href="https://github.com/iamsajithk"
            target="_blank"
            rel="noreferrer"
          >
            Checkout my projects on{" "}
            <GithubSVG className="ml-6 h-12 w-12 inline" />
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
